import { IonButton } from "@ionic/react";
import clsx from "clsx";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import "./iconButton.scss";

export type IconButtonProps = ComponentPropsWithoutRef<typeof IonButton>;

export const IconButton = forwardRef<HTMLIonButtonElement, IconButtonProps>((props, forwardedRef) => {
    const { fill = "clear", size = "small", ...restProps } = props;

    return (
        <IonButton
            {...restProps}
            ref={forwardedRef}
            fill={fill}
            size={size}
            className={clsx("icon-button", props.className)}
        />
    );
});
