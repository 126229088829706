import { createScopedContext } from "@/util/context";

type FaCollapsibleContextProps = {
    disabled: boolean;
    //
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const [FaCollapsibleContext, useFaCollapsibleContext] =
    createScopedContext<FaCollapsibleContextProps>("FaCollapsibleContext");
