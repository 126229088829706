import { CSSProperties, ReactNode } from "react";
import useResizeObserver from "use-resize-observer";
import { useFaCollapsibleContext } from "@/components/FaCollapsible/FaCollapsibleContext/FaCollapsibleContext";
import "./faCollapsibleBody.scss";

type FaCollapsibleBodyProps = {
    children: ReactNode;

    className?: string;
};

export function FaCollapsibleBody(props: FaCollapsibleBodyProps) {
    const { open } = useFaCollapsibleContext();

    const { ref, height = 0 } = useResizeObserver<HTMLDivElement>({
        box: "border-box",
    });

    const styles: CSSProperties = {
        height: open ? height : 0,
        opacity: open ? 1 : 0,
    };

    return (
        <div className="fa-collapsible-body" style={styles}>
            <div ref={ref} className={props.className}>
                {props.children}
            </div>
        </div>
    );
}
