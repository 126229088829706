import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { useFaCollapsibleContext } from "@/components/FaCollapsible/FaCollapsibleContext/FaCollapsibleContext";
import "./faCollapsibleTrigger.scss";

type FaCollapsibleTriggerProps = ComponentPropsWithoutRef<typeof Slot>;

export function FaCollapsibleTrigger(props: FaCollapsibleTriggerProps) {
    const { disabled, open, setOpen } = useFaCollapsibleContext();

    const handleToggle = () => {
        if (disabled) {
            return;
        }
        setOpen(!open);
    };

    return (
        <Slot
            {...props}
            className={clsx(
                "fa-collapsible-trigger",
                {
                    "fa-collapsible-trigger--disabled": disabled,
                },
                props.className
            )}
            onClick={handleToggle}
        />
    );
}
