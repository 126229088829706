import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { FaCollapsibleContext } from "@/components/FaCollapsible/FaCollapsibleContext/FaCollapsibleContext";

type FaCollapsibleRootProps = {
    /**
     * @default false
     */
    defaultOpen?: boolean;
    disabled?: boolean;
    children: ReactNode;
};

export type FaCollapsibleRef = {
    open: () => void;
    close: () => void;
    toggle: () => void;
};

export const FaCollapsibleRoot = forwardRef<FaCollapsibleRef, FaCollapsibleRootProps>((props, forwardedRef) => {
    const { defaultOpen = false, disabled = false, children } = props;

    const [open, setOpen] = useState(defaultOpen);

    useImperativeHandle(
        forwardedRef,
        () => ({
            open: () => setOpen(true),
            close: () => setOpen(false),
            toggle: () => setOpen(prev => !prev),
        }),
        [setOpen]
    );

    return (
        <FaCollapsibleContext.Provider value={{ disabled, open, setOpen }}>{children}</FaCollapsibleContext.Provider>
    );
});
