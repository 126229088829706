import { FaCollapsibleBody } from "./FaCollapsibleBody/FaCollapsibleBody";
import { FaCollapsibleIcon } from "./FaCollapsibleIcon/FaCollapsibleIcon";
import { FaCollapsibleRef, FaCollapsibleRoot } from "./FaCollapsibleRoot/FaCollapsibleRoot";
import { FaCollapsibleTrigger } from "./FaCollapsibleTrigger/FaCollapsibleTrigger";

export const Root = FaCollapsibleRoot;
export type Ref = FaCollapsibleRef;
export const Trigger = FaCollapsibleTrigger;
export const Icon = FaCollapsibleIcon;
export const Body = FaCollapsibleBody;
