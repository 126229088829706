import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useFaCollapsibleContext } from "@/components/FaCollapsible/FaCollapsibleContext/FaCollapsibleContext";
import { IconButton, IconButtonProps } from "@/components/organization/sharedComponents/IconButton/IconButton";
import "./faCollapsibleIcon.scss";

type FaCollapsibleIconProps = IconButtonProps;

export function FaCollapsibleIcon(props: FaCollapsibleIconProps) {
    const { disabled, open } = useFaCollapsibleContext();

    if (props.disabled || disabled) {
        return null;
    }

    return (
        <IconButton color="dark" fill="clear" {...props} className={clsx("fa-collapsible-icon", props.className)}>
            <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx("fa-collapsible-icon__icon", {
                    "fa-collapsible-icon__icon--opened": open,
                    "fa-collapsible-icon__icon--closed": !open,
                })}
            />
        </IconButton>
    );
}
